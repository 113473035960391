import { useRouter } from 'next/router';

import TransText from 'next-translate/TransText';

import Trans from '@components/Trans';
import CustomLink from '@components/global/CustomLink';
import { Button } from '@components/ui/Button';
import DeskoptBr from '@components/ui/DeskoptBr';
import Mark from '@components/ui/Mark';

import useTranslation from '@hooks/useTranslation';

import styles from './Pricing.module.css';
import arrowImg from './assets/arrow_faq_icon.svg';

const ns = 'pages/referral-program/refer-a-friend/index';
function Pricing() {
    const { t } = useTranslation(ns);
    const { locale } = useRouter();
    return (
        <div className={styles.pricing}>
            <h2>
                <TransText
                    text={t('pricingTitle').replaceAll(' class="gr-mark"', '')}
                    components={{
                        mark: <Mark textColor={'black'} />,
                        br: <DeskoptBr />,
                    }}
                />
            </h2>
            <div className={styles.plans}>
                <article>
                    <div className={styles.top}>
                        <h3>{t('pricingFree')}</h3>
                        <p>{t('pricingFreeText')}</p>
                        <div className={`${styles.price} ${styles.custom}`}>
                            <TransText
                                text={t('pricingFreePrice').replaceAll(' class="currency"', '')}
                                components={{
                                    sup: <sup className={styles.currency} />,
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.bottom}>
                        <div className={styles['toggle-details']}>
                            <a
                                href="#"
                                className={styles['toggle-details-on']}
                            >
                                {t('checkDetails')}
                                <img
                                    loading="lazy"
                                    width="15"
                                    height="15"
                                    className={styles.chevron}
                                    src={arrowImg.src}
                                    aria-label="arrow"
                                />
                            </a>
                            <a
                                href="#"
                                className={styles['toggle-details-off']}
                            >
                                {t('hideDetails')}
                                <img
                                    loading="lazy"
                                    width="15"
                                    height="15"
                                    className={styles.chevron}
                                    src={arrowImg.src}
                                    aria-label="arrow"
                                />
                            </a>
                        </div>
                        <div className={styles.includes}>
                            <h4>{t('pricingFreeListTitle')}</h4>
                            <ul>
                                <Trans
                                    i18nKey="pricingFreeList"
                                    components={{
                                        li: <li />,
                                    }}
                                    ns={ns}
                                />
                            </ul>
                        </div>
                    </div>
                </article>
                <article className={styles.emphasize}>
                    <div className={styles.top}>
                        <h3>{t('pricingPaid')}</h3>
                        <p>{t('pricingPaidText')}</p>
                        <p className={styles['pre-price']}>{t('pricingPaidPriceHeading')}</p>
                        <div className={styles.price}>
                            <span className={styles['formatted-price']}>
                                <TransText
                                    text={t('pricingPaidPrice').replaceAll(' class="currency"', '').replaceAll('&nbsp;', '')}
                                    components={{
                                        span: <span className={styles.currency} />,
                                    }}
                                />
                            </span>
                            <span className={styles.period}>{t('per_month')}</span>
                        </div>
                    </div>
                    <div className={styles.bottom}>
                        <div className={styles['toggle-details']}>
                            <a
                                href="#"
                                className={styles['toggle-details-on']}
                            >
                                {t('checkDetails')}
                                <img
                                    loading="lazy"
                                    width="15"
                                    height="15"
                                    className={styles.chevron}
                                    src={arrowImg.src}
                                    aria-label="arrow"
                                />
                            </a>
                            <a
                                href="#"
                                className={styles['toggle-details-off']}
                            >
                                {t('hideDetails')}
                                <img
                                    loading="lazy"
                                    width="15"
                                    height="15"
                                    className={styles.chevron}
                                    src={arrowImg.src}
                                    aria-label="arrow"
                                />
                            </a>
                        </div>
                        <div className={styles.includes}>
                            <h4>{t('pricingPaidListTitle')}</h4>
                            <ul>
                                <Trans
                                    i18nKey="pricingPaidList"
                                    components={{
                                        li: <li />,
                                    }}
                                    ns={ns}
                                />
                            </ul>
                        </div>
                    </div>
                </article>
                <article>
                    <div className={styles.top}>
                        <h3>
                            <Trans
                                i18nKey="pricingMax"
                                components={{
                                    sup: <sup />,
                                }}
                                ns={ns}
                            />
                        </h3>
                        <p>{t('pricingMaxText')}</p>
                        <div className={`${styles.price} ${styles.custom}`}>
                            <TransText
                                text={t('pricingMaxPrice').replaceAll(' class="currency"', '')}
                                components={{
                                    sup: <sup className={styles.currency} />,
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.bottom}>
                        <div className={styles['toggle-details']}>
                            <a
                                href="#"
                                className={styles['toggle-details-on']}
                            >
                                {t('checkDetails')}
                                <img
                                    loading="lazy"
                                    width="15"
                                    height="15"
                                    className={styles.chevron}
                                    src={arrowImg.src}
                                    aria-label="arrow"
                                />
                            </a>
                            <a
                                href="#"
                                className={styles['toggle-details-off']}
                            >
                                {t('hideDetails')}
                                <img
                                    loading="lazy"
                                    width="15"
                                    height="15"
                                    className={styles.chevron}
                                    src={arrowImg.src}
                                    aria-label="arrow"
                                />
                            </a>
                        </div>
                        <div className={styles.includes}>
                            <h4>{t('pricingMaxListTitle')}</h4>
                            <ul>
                                <Trans
                                    i18nKey="pricingMaxList"
                                    components={{
                                        li: <li />,
                                    }}
                                    ns={ns}
                                />
                            </ul>
                        </div>
                    </div>
                </article>
            </div>
            <Button
                label={t('pricingBtn')}
                customLink={<CustomLink href={{ pathname: '/pricing' }} />}
            />
        </div>
    );
}

export default Pricing;
