import styles from './Testimonial.module.css';

export default function Testimonial(props) {
    return (
        <div className={[styles.testimonial, props.className].join(' ')}>
            <div className={styles.image}>
                {props.img && props.img.x1 ? (
                    <img
                        alt={''}
                        loading="lazy"
                        width={props.img.width}
                        height={props.img.height}
                        src={props.img.x1}
                        srcSet={`${props.img.x2} 2x`}
                    />
                ) : (
                    props.img
                )}
            </div>
            <div className={styles.text}>
                {props.header && <h3>{props.header}</h3>}
                <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
                <small
                    className={!props.secondVersion ? styles.role : styles.roleAndName}
                    dangerouslySetInnerHTML={{ __html: props.name }}
                ></small>
                {props.url && (
                    <a
                        href={props.url}
                        className={styles.text_link}
                    >
                        {props.textUrl}
                    </a>
                )}
            </div>
        </div>
    );
}
