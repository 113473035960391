import TransText from 'next-translate/TransText';

import styles from './Feature.module.css';

interface FeatureProps {
    picture: JSX.Element;
    icon: JSX.Element;
    link: (className: string) => JSX.Element;
    title: string;
    description: string;
}

function Feature({ picture, icon, title, description, link }: FeatureProps) {
    return (
        <div className={styles.feature}>
            <div>
                <figure>
                    {picture}
                    <span className={styles.icon}>{icon}</span>
                </figure>
                <h2>{title}</h2>
                <p>
                    <TransText
                        text={description}
                        components={{
                            br: <br />,
                        }}
                    />
                </p>
            </div>
            {link(styles.more)}
        </div>
    );
}

export default Feature;
