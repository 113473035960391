import { useRouter } from 'next/router';

import TransText from 'next-translate/TransText';

import { Button } from '@components/ui/Button';
import DeskoptBr from '@components/ui/DeskoptBr';
import Mark from '@components/ui/Mark';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteStartFree } from '@static_components/laravelLinks';

import styles from './Hero.module.css';

const ns = 'pages/referral-program/refer-a-friend/index';
function Hero() {
    const { t } = useTranslation(ns);
    const { locale } = useRouter();
    return (
        <div className={styles.hero}>
            <h1 className={styles.header}>
                <TransText
                    text={t('topTitle').replaceAll(' class="gr-mark"', '')}
                    components={{
                        mark: <Mark />,
                        br: <DeskoptBr />,
                    }}
                />
            </h1>
            <Button
                label={t('topBtn')}
                href={laravelRouteStartFree(locale)}
            />
        </div>
    );
}

export default Hero;
